import React from 'react'
import { navigate } from 'gatsby-link'

import Layout from '../../components/layout'
import { TextField, TextAreaField, encode } from '../../components/forms'
import { classes } from '../../utils'

import * as styles from '../../styles/forms.module.sass'

class Kontakt extends React.Component {
  timer = null
  state = {
    emailDirty: false,
    messageDirty: false,
    sending: false,
  }

  onEmailFocus = () => {
    this.setState({ emailDirty: true })
  }

  onMessageFocus = () => {
    this.setState({ messageDirty: true })
  }

  onEmailBlur = (e) => {
    if (!e.target.value) {
      this.setState({ emailDirty: false })
    }
  }

  onMessageBlur = (e) => {
    if (!e.target.value) {
      this.setState({ messageDirty: false })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const [formName, trap, email, message] = form.elements
    if (!email.value || !message.value) return false

    this.setState({ sending: true })

    this.timer = setTimeout(() => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          [formName.name]: [formName.value],
          [trap.name]: [trap.value],
          [email.name]: [email.value],
          [message.name]: [message.value],
        }),
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error) => {
          alert(error)
          this.setState({ sending: false })
        })
    }, 1000)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    return (
      <Layout location={this.props.location} customClass="contacts">
        <header>
          <h1>Kontakt</h1>
        </header>
        <div className="page-content">
          <div className="cols">
            <section>
              <h2>Odbor všestrannosti</h2>
  
              <h4>Předseda odboru</h4>
              <ul>
                <li>Ing. Martin Matura</li>
                <li>+420 736 740 307</li>
                <li>vsestrannost@sokolpisek.cz</li>
              </ul>

              <h4>Hospodářka</h4>
              <ul>
                <li>Marie Suková</li>
                <li>+420 605 969 704</li>
                <li>vsestrannost.hospodar@sokolpisek.cz</li>
              </ul>

              <h4>Náčelník</h4>
              <ul>
                <li>MUDr. Tomáš Piksa</li>
                <li>+420 607 825 706</li>
                <li>tomas.piksa@seznam.cz</li>
              </ul>

              <h4>Náčelnice</h4>
              <ul>
                <li>Ing. Tereza Cendelínová</li>
                <li>+420 737 505 849</li>
                <li>dar-ja@seznam.cz</li>
              </ul>
  
              <h4>1. Místonáčelník</h4>
              <ul>
                <li>Jakub Čudlý, DiS.</li>
                <li>+420 736 634 064</li>
                <li>cudlyk@seznam.cz</li>
              </ul>

              <h4>1. Místonáčelnice</h4>
              <ul>
                <li>Ing. Jana Beránková</li>
                <li>+420 601 566 568</li>
                <li>jana.velatova@centrum.cz</li>
              </ul>
  
              <h4>Místonáčelník</h4>
              <ul>
                <li>Ing. Ladislav Cibulka</li>
                <li>+420 602 468 355</li>
                <li>lcibulka@sokol.eu</li>
              </ul>
  
              <h4>Místonáčelnice</h4>
              <ul>
                <li>Marie Suková</li>
                <li>+420 605 969 704</li>
                <li>majka.suk@seznam.cz</li>
              </ul>
  
            </section>
            <section>
              <h2>Máte dotaz? Napište nám!</h2>
              <form
                className={classes(
                  styles.form,
                  this.state.sending && 'is-sending'
                )}
                name="contact"
                action="/kontakt/dekujeme"
                method="POST"
                netlify-honeypot="trap"
                netlify="true"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <label className="trap">
                  <input name="trap" type="text" />
                </label>
                <TextField
                  name="email"
                  dirty={this.state.emailDirty}
                  onFocus={this.onEmailFocus}
                  onBlur={this.onEmailBlur}
                >
                  Váš email
                </TextField>
                <TextAreaField
                  name="message"
                  dirty={this.state.messageDirty}
                  onFocus={this.onMessageFocus}
                  onBlur={this.onMessageBlur}
                >
                  Zpráva
                </TextAreaField>
                <button type="submit">Poslat zprávu</button>
              </form>

              <h4>Fakturační údaje</h4>
              <p>
                Tělocvičná jednota Sokol Písek
                <br />
                Tyršova 395/3
                <br />
                397 01 Písek
                <br />
                IČO: 48256943
                <br />
                č.ú.: 9666857001/5500
              </p>
            </section>
          </div>
          <h2>Kde nás najdete?</h2>
        </div>
        <div className="map-wrapper">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2601.1991657196872!2d14.146802515690473!3d49.31051177933442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b4ff5abeb8721%3A0x3be02fa7fa0ea6e7!2zVHlyxaFvdmEgMzk1LzMvMSwgQnVkxJtqb3ZpY2vDqSBQxZllZG3Em3N0w60sIDM5NyAwMSBQw61zZWs!5e0!3m2!1scs!2scz!4v1536619865746"
            width="800"
            height="600"
            frameBorder="0"
            style={{ border: 0, width: '100%', height: '100%' }}
            allowFullScreen
          />
        </div>
      </Layout>
    )
  }
}

export default Kontakt
